.popup-container {
  text-align: center;
}

.register-button {
  /* background-color: #007bff; */
  color: white;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  text-align: center;
  position: relative;
}

.gift-icon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: block;
}

h2 {
  font-size: 24px;
  margin-top: 20px;
}

p {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.close-button {
  background-color: transparent;
  color: #007bff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  bottom: -14px;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: block;
  max-width: 100px;
  
}
.timer-container {
  margin-top: 10px;
}

.timer-container p {
  font-size: 16px;
  margin-bottom: 10px;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer div {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  margin-right: 5px;
  border-radius: 15px;
  font-size: 18px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a{
  color:#007bff;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .popup-inner {
    max-width: 60%;
  }
}