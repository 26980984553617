@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Nunito&family=Open+Sans:wght@400;700&family=Oswald&family=Poppins&family=Roboto&family=Source+Sans+Pro:wght@200;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
    height: 100vh;
    padding: 5rem;
 
}
.vector-graphics {
    filter: drop-shadow(0 40px 10px rgba(0,0,0,0.3));
    animation: vectorAnimation 1s infinite ease-in-out alternate;
}
@keyframes vectorAnimation {
    to {
        transform: translateY(-10px);
    }
}
.company {
  color:  rgb(183, 182, 182);
  font-size: 50px;
  font-weight: bold;
  font-family:monospace;
  padding: 2rem;
  
}
.brandsBanner {
  display: flex;
  flex-wrap: wrap;
}
.container2 {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.container2 video {
    box-shadow: 0 0 10px rgba(0,0,0,0.175);
    width: 60%;
    border-radius: 5px;
    outline: none;
}
@media screen and (max-width: 600px) {
    .container2 {
        height: unset;
        padding: 4vh 0;
    }
    .container2 video {
        width: 100%;
    }
    .brandsBanner {
      display: flex;
      flex-wrap: wrap;
    }
    .company {
      color: rgb(183, 182, 182);
      font-size: 1.5rem;
      font-weight: bold;
      font-family:monospace;
      padding: 1rem 0;
      
    }
}
/* leaderboard */
.leaderboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.5;
  transition: all .3s ease-in-out;
  font-family: 'Poppins', sans-serif;
  
}

.card{
  min-height: 475px;
  width: 800px;
  margin: 2rem;
  border-radius: 15px;
  background: #0963ac;
}

.card .header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  color: #ddd;
}

.card .header .title{
  font-weight: 300;
}

.one .sort{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14.1px;
}
.headerdc {
  color: #25A84A;
}
.one .sort .day{
  padding: 0.4rem 1.2rem;
  margin: 0 0.1rem;
  cursor: pointer;
}

.one .sort .day.active,
.one .sort .day:active{
  background: rgba(210, 255, 213, 0.3);
  border-radius: 25px;
}

::selection{
  background: rgba(210, 255, 213, 0.3);
}

.photo{
  width: 75px;
  background: #fff;
  border-radius: 50%;
  border: 5px solid cyan;
  box-shadow: 0 0 20px cyan;
  margin: 1rem 0;
}

.main{
  width: 85px;
}

.profile{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.profile .person{
  display: flex;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profile .person.first{
  z-index: 10;
  transform: translateY(-10%);
}

.first .fa-crown{
  color: gold;
  filter: drop-shadow(0px 0px 5px gold);
}

.num{
  color: white;
}

.fa-caret-up{
  color: cyan;
  font-size: 21px;
}

.link{
  margin: 0.2rem 0;
  color: #fff;
  margin-top: -0.3rem;
  font-size: 13px;
}

.points{
  color: cyan;
  font-size: 17px;
}

.second{
  margin-right: -0.7rem !important;
}

.third{
  margin-left: -0.7rem !important;
}

.p_img{
  width: 50px;
  background: #fff;
  border-radius: 50%;
}

.flex{
  display: flex;
  align-items: center;
}

.others{
  display: flex;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

.info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  background: rgba(210, 255, 213, 0.3);
}

.info .points{
  margin-left: 0.2rem;
  margin-right: 1.2rem;
}

.info .link{
  margin: 0 1rem;
}

.rank{
  display: flex;
  align-items: center;
  margin: 0 1rem;
  flex-direction: column-reverse;
}

.rank i{
  margin-top: -5px !important;
}

.rank .num{
  margin: 0 !important; 
}
@media screen and (max-width: 600px){
  .card{
    width:275px;
  }
}

/* faq */

.attribution { 
  font-size: 11px; text-align: center; 
  background-color: hsl(240, 5%, 91%);
  padding: 8px 0 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.attribution a { color: hsl(228, 45%, 44%); }

/* global */
/*================================================*/


/* body {
  margin:0;
  font-family: var(--principal-font);
  font-size: 12px;
  color: var(--neutral-dark-color);

  background: var(--bg-gradient);
  background-size: cover;
} */

.card-wrapper {
  /* min-height: 100vh; */
  padding-bottom: 50px; /* footer fixed */
  padding-top: 25px;
}

p {
  margin: 0 0 12px;
  line-height: 1.5;
}

h2 {
  margin: 12px 0;
}


/* generics */
/*================================================*/

.content-cc{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* FAQ card */
/*================================================*/

.faq-card {

  background-color: #fff;
  border-radius: 20px;

  max-width: 310px;
  margin-top: 90px;
  
  box-shadow: 0px 60px 50px -25px rgba(0, 0, 0, 0.35);
}

/* FAQ card: imagenes */
/*================================================*/


.faq-ilustrations{
  position: relative;
  font-family: 'Poppins', sans-serif;
}

.faq-ilustrations .mobile.ilust{
  position: absolute;
  top:-110px;
  left:-30px;
  right: 0;
  font-family: 'Poppins', sans-serif;
}

.mobile{
  display: block;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}


/* FAQ card: main */
/*================================================*/

.faq-content {
  padding: 9px 25px 3rem; 
  font-family: 'Poppins', sans-serif;
}

.faq-content h1 {
  font-size: 32px;
  text-align: center;
  color: var(--primary-dark-color);
  font-family: 'Poppins', sans-serif;
}

.faq-accordion {
  padding: 8px 0;
  border-bottom: 1px solid hsl(240, 5%, 91%);
  font-family: 'Poppins', sans-serif;
}


/* FAQ card: main title */
/*================================================*/

/* checkbox tgg-title*/
input.tgg-title {
  appearance: unset;
  all:unset;
}

.faq-accordion-title label{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.faq-accordion-title h2{
  font-size: 12px /*var(--font-size)*/;
  
  font-weight: 400;
  color: var(--neutral-dark-color);
}

.faq-accordion-title span{
  margin-left: auto;
  transition: transform .4s ease-in-out;
}


/* FAQ card: main content */
/*================================================*/

.faq-accordion-content {
  color: var(--neutral-soft-color);
  overflow: hidden;
  max-height: 0;
  transition: max-height .4s ease-in-out;
}


/* Effects */
/*================================================*/

/* main title, accordion title effects */

.faq-accordion-title:hover h2{
  color: var(--primary-soft-color)
}

/* onclick "" */
.faq-accordion .tgg-title:checked + div>label>h2 {
  font-weight: 700;
}

.faq-accordion .tgg-title:checked + div>label>span {
  will-change: transform;
  transform: rotate(180deg);
}

/* main content, acordion text effect */

.faq-accordion .tgg-title:checked ~ .faq-accordion-content{
  will-change: max-height;
  max-height: 80px;
}


.internship {
  margin-bottom: 1rem;
  transition: all 0.5s;
  width: 250px;
}
.internship:hover {
  transform: translateY(-10px);
}
.headingele {
  font-family: 'Poppins', sans-serif;
  
}
.alumniHeading {
  text-align: center;
  font-size: 3em;
  font-weight: 600;
  /* text-shadow:2px 3px 1.5px #0963ac; */
  font-family: 'Poppins', sans-serif;
}
.mainHeading {
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  /* text-shadow:2px 3px 1.5px #0963ac; */
  font-family: 'Poppins', sans-serif;
}
.mainHeading1 {
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
  /* text-shadow:2px 3px 1.5px #0963ac; */
  font-family: 'Poppins', sans-serif;
}
#mainHeading {
  font-size: 6rem; /* Default font size for desktop */
  font-weight: 800;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  /* text-shadow:2px 3px 2px #0963ac; */
  animation: headingAnimation 10s ease-in-out infinite alternate;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  #mainHeading {
      font-size: 2rem; /* Font size for mobile devices */
      font-weight: 800;
      font-family: 'Poppins', sans-serif;
      animation: headingAnimation 6s ease-in-out infinite alternate;
  }
}
@keyframes headingAnimation {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(1.1);
  }
}


:root {
  --first-clr: #0963ac;
}


.first-clrd {
  color: var(--first-clr);
}

.headerdd {
  color:var(--first-clr);
  font-size: 5rem;
  font-weight: bolder;
  font-family: "Bricolage Grotesque", sans-serif;
}
.headerddd {
  color:var(--first-clr);
  font-size: 4rem;
  font-weight: bolder;
  padding-left: 7px;
  font-family: "Bricolage Grotesque", sans-serif;
  text-align: center;
}

.landing-paged {
  font-family: 'Titillium Web', sans-serif;
}

.landing-paged {
  padding: 80px 0;
  user-select: none;
}
.landing-paged .containerd {
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.landing-paged .contentd {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-paged .contentd .text-contd {
  width: 60%;
}
.landing-paged .contentd .text-contd .headerd {
  color: #25A84A;
  font-size: 3rem;
  font-weight: 900;
  line-height: 1.1;
}
.landing-paged .contentd .text-contd .descriptiond {
  font-size: 16px;
  margin: 30px 0;
  color: #777;
  font-weight: 400;
  width: 80%;
}
.landing-paged .contentd .text-contd .btnd {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  padding: .6rem 1rem;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  box-shadow: 0 0px 5px rgba(0, 0, 0, .1);
  transition: all .2s linear;
}
.landing-paged .contentd .text-contd .btn-discoverd {
  min-width: 160px;
  margin-right: 5px;
  color: #FFF;
  background-color: var(--first-clr);
}
.landing-paged .contentd .text-contd .btn-discoverd:hover {
  border-color: var(--first-clr);
  background-color: transparent;
  color: var(--first-clr);
}
.landing-paged .contentd .text-contd .btn-playd {
  color: var(--first-clr);
}
.landing-paged .contentd .text-contd .btn-playd:hover {
  border-color: var(--first-clr);
  background-color: var(--first-clr);
  color: #FFF;
}

.landing-paged .contentd .img-contd {
  width: 35%;
  position: relative;
  
}
.landing-paged .contentd .img-contd::before,
.landing-paged .contentd .img-contd::after {
  content: '';
  position: absolute;
  z-index: -1;
  background-color: var(--first-clr);
}
.landing-paged .contentd .img-contd::before {
  top: -5%;
  left: -7%;
  width: 100%;
  height: 60px;
}
.landing-paged .contentd .img-contd::after {
  height: 25vh;
  width: 25vh;
  max-width: 180px;
  max-height: 180px;
  bottom: -5%;
  left: -5%;
  border-radius: 0 0 0 15px;
}
.landing-paged .contentd .img-contd .imgd {
  width: 100%;
  display: block;
  border-radius: 15px;
  box-shadow: 0 0 15px #fffa65;
}

@media screen and (max-width: 650px) {
  .headerdd {
    color:var(--first-clr);
    font-size: 3.7rem;
    font-weight: bolder;
  }
  .landing-paged .contentd .text-contd .headerd {
    color: #53c774;
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 1.1;
  }
  .landing-paged .contentd {
    flex-direction: column;
  }
  .landing-paged .contentd .text-contd,
  .landing-paged .contentd .text-contd .descriptiond {
    width: 100%;
  }
  .landing-paged .contentd .text-contd {
    text-align: center;
  }
  .landing-paged .contentd .img-contd {
    width: 80%;
    margin-top: 80px;
  }
}
