
.main1 {
  max-width: 1200px;
  margin: 0 auto;
}

.cards1 {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards1_item {
  display: flex;
  padding: 1rem;
}

.card1_image {
  position: relative;
  max-height: 250px;
}

.card1_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card1_price {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 0.25rem;
  background-color: #0963ac;
  font-size: 18px;
  font-weight: 700;
}

.card1_price span {
  font-size: 12px;
  margin-top: -2px;
}

.note1 {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 4px 8px;
  border-radius: 0.25rem;
  height: 30px;
  width: 35%;
  color: aliceblue;
  background-color: #0963ac;
  font-size: 14px;
  font-weight: 700;
}

@media (min-width: 40rem) {
  .cards1_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards1_item {
    width: 33.3333%;
  }
}

.card1 {
  background-color: transparent;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card1_content {
  position: relative;
  padding: 16px 12px 32px 24px;
  margin: 16px 8px 8px 0;
  max-height: 290px;
  overflow-y: scroll;
}

.card1_content::-webkit-scrollbar {
  width: 8px;
}

.card1_content::-webkit-scrollbar-track {
  box-shadow: 0;
  border-radius: 0;
}

.card1_content::-webkit-scrollbar-thumb {
  background: #0963ac;
  border-radius: 15px;
}

.card1_title {
  position: relative;
  margin: 0 0 24px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.card1_title::after {
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0963ac;
  content: "";
}

hr {
  margin: 24px auto;
  width: 50px;
  border-top: 2px solid #0963ac;
}

.card1_text p {
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 1.5;
}

.card1_text p:last-child {
  margin: 0;
}
