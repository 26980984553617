@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Nunito&family=Open+Sans:wght@400;700&family=Oswald&family=Poppins&family=Roboto&family=Source+Sans+Pro:wght@200;600&display=swap');
* {
  font-family: 'Poppins', sans-serif;
}
.popup-container {
    text-align: center;
  }
  
  .register-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    text-align: center;
    position: relative;
  }
  
  .success-icon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: block;
  }
  
  h2 {
    font-size: 24px;
    margin-top: 20px;
  }
  
  p {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .close-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    bottom: -30px;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: block;
    max-width: 150px;
  }
  .requestHeading {
    margin-top: 8rem;
    font-size: 1.2rem;
  }
  .requesttitle {
    font-size: 3rem;
  }
  