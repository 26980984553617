@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Nunito&family=Open+Sans:wght@400;700&family=Oswald&family=Poppins&family=Roboto&family=Source+Sans+Pro:wght@200;600&display=swap');
body,
html {
    font-family: 'Roboto', sans-serif;
}

      @font-face {
    font-family: 'ZohoPuviFontRegular';
    src: url('../public/Zoho\ Puvi\ Regular.ttf') format('truetype');
    /* You can include additional font file formats and sources here if needed */
}

* {
    font-family: 'ZohoPuviFontRegular', sans-serif;
}

