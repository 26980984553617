bodys {
    align-items: center;
    background: transparent;
    display: flex;
    justify-content: center;
}
@keyframes scroll {
    0% {
        transform: translateX(0);
   }
    100% {
        transform: translateX(calc(-250px * 7));
   }
}
.slider {
    background: transparent;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 200px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100vw;
}
.slider::before, .slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 400px;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
.slider::before {
    left: 0;
    top: 0;
}
.slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}
.slider .slide {
    height: 100px;
    width: 250px;
    padding-left: 2.5rem;
    padding-top: 2.5rem;
}
