@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Nunito&family=Open+Sans:wght@400;700&family=Oswald&family=Poppins&family=Roboto&family=Source+Sans+Pro:wght@200;600&display=swap');
* {
  font-family: 'Poppins', sans-serif;
}
.mainHeading1 {
  color: rgba(255, 255, 255,0.7);
    font-size: 3rem;
    font-weight: 800;
    font-family: "Bricolage Grotesque", sans-serif;
  }
  